@use "../abstracts" as *;

@import "~@splidejs/splide/dist/css/splide.min.css";

// Splide Overwrite
.splide {
    &__arrow {
        background: transparent;

        &--next {
            right: -1em;
        }

        &--prev {
            left: -1em;
        }

        svg {
            fill: #a7a7a7;
        }

        @include for-desktop-up {
            &--next {
                right: -2em;
            }

            &--prev {
                left: -2em;
            }
        }
    }

    &__pagination {
        bottom: -1.5em;

        &__page {
            width: 10px;
            height: 10px;
            // background: color(primary);
        }

        &__page.is-active {
            // background: color(primary, 500);
        }
    }
}
