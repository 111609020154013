@use "../abstracts" as *;
@use "../components/card";
@use "../components/splide";
@import "../../variables";

.banner {
  position: relative;
  overflow: hidden;

  &-splide {
    li {
      @include flex(flex, column, center, center);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__content {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 10;
      @include flex(flex, column, flex-start, center);
      width: 100%;
      height: 100%;
      padding: 1rem 0.5rem;
      transform: translate(-50%, -50%);

      h2 {
        color: white;
        font-size: size(title, 500);

        strong {
          font-weight: 900;
        }

        @include for-phone-only{
            font-size: size(title, 300);
        }
      }

      p {
        color: white;

        &:last-of-type {
          margin-bottom: 1.5rem;
        }
      }

      @include for-desktop-up {
        max-width: 90%;
        height: auto;
        background-color: transparent;
        left: 10em;
        transform: translateY(-50%);
      }

      @include for-tablet-portrait-up {
        p {
          font-size: size(text, 400);
        }
      }
    }

    &__button {
      min-width: 207px;
      height: 49px;

      &:hover {
        color: color(primary, 100) !important;
        background-color: white !important;
      }
    }

  }

  .splide {
    &__arrow {
      &--next {
        right: 2rem;
      }

      &--prev {
        left: 2rem;
      }

      svg {
        width: 29px;
        height: 46px;
        fill: white;
      }
    }

    &__pagination {
      bottom: 1.5rem;

      &__page {
        &:not(.is-active) {
          background-color: transparent;
          border: 1px solid white;
        }
      }
    }
  }

  @include for-tablet-portrait-only {
    .splide__arrow {
      top: 60%;
    }
  }
}

.team {
  &__title {
    font-size: 1.75rem;
    font-weight: bold;
    color: $gray-darker;

    &::after {
      width: 130px;
      margin: 12px 0;
    }
  }

  &__text {
    h3 {
      color: $blue;
      font-size: 1.75rem;
    }

    p strong {
      color: $blue;
      font-weight: 700;
      font-size: 40px;
      line-height: 54px;
    }

    p {
      font-size: size(text, 400);
      line-height: 19px;
      color: color(gray, 600)
    }
  }

  &__link {
    font-weight: 700;
  }
}

.news {
  background-color: #F8F8F8;

  &__title {
    font-size: 1.75rem;
    font-weight: bold;

    &::after {
      margin: 0.5rem auto;
    }
  }

  &-card {
    @include grid(grid, 100%, 250px 100px 150px, 1rem);

    &__figure {
        justify-content: center;
        display: flex   ;
      img {
        width: 250px;
        height: 250px;
        object-fit: cover;
        border-radius: 7px;
      }
    }

    &__title {
      font-size: size(title, 100);
      font-weight: bold;
      text-align: center;
      color: color(primary, 100);
    }

    &__text {
      position: relative;

      p {
        color: color(grayScale, 600);
        text-align: justify;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 2px;
        background-color: color(primary);
      }
    }
  }

  &-splide {
    .splide__arrow {
      svg {
        fill: #27384A;
        font-size: 36px;
      }
    }
  }
}

.specialties {
  background-color: color(white, 100);

  &__title {
    font-size: 1.75rem;
    font-weight: bold;

    &::after {
      margin: 0.5rem auto;
    }
  }

  &-card {
    max-width: 259px;
    min-height: 159px;
    box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.12);
    padding: 16px;
    margin: auto;
    border-radius: 11px;

    &__figure {
      background-color: unset;

      img {
        width: 70px;
        height: 60px;
        object-fit: cover;
        border-radius: 7px;
      }
    }

    &__title {
      font-size: size(text, 500);
      text-align: center;
      color: color(blackScale, 400);
      margin-bottom: 0;
    }

    &:hover {
      background-color: color(primary, 900);

      .specialties {
        &-card {
          &__figure {
            img {
              fill: color(white, 100);
            }
          }

          &__title {
            color: color(white, 100);
          }
        }
      }
    }
  }
}

.blog {
  &__title {
    font-size: 1.75rem;
    font-weight: bold;

    &::after {
      margin: 0.5rem auto;
    }
  }
}

.middle {
  margin: 4rem 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 446px;
  @include for-phone-only{
    flex-direction: column;
  }

  &__column {
    background-repeat: no-repeat;
    background-position: 17% 0;
  }

  &__title {
    color: white;
    font-size: size(title, 500);
    font-weight: bold;
  }

  &__text {
    font-size: size(text, 300);
    color: white;
  }

  &__link {
    width: 244px;
  }

  &__image {
    position: absolute;
    left: 66%;

    @include for-phone-only{
        position: unset;
      }
  }
}

.covenants {
  &__title {
    font-size: 1.75rem;
    font-weight: bold;

    &::after {
      margin: 0.5rem auto;
    }
  }

  &__link {
    color: color(grayScale, 600);
  }

  &__image {
    @extend %transitionDefault;
  }
}
